import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { setAboutYouStage, setHubspotStage } from "../../redux/stages";
import {
  setActiveUser,
  setEventDay,
  setLoggedIn,
  setUserData,
} from "../../redux/users";
import ApiService from "../../service/axiosUtils";
import { getHubspotUser } from "../../service/hubspotAxios";
import store from "../../redux/store";
import { getPricingTable, getIndiaPricingTable } from "../../redux/prices";
import { USER_APPLICANT, USER_REJECTED } from "../../utils/constants";

function LandingPrivateComponent() {
  const dispatch = useDispatch();


  const profile = useSelector(
    (state) => state?.users?.user?.attributes?.profile
  );

  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);


  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        const refreshToken = session.getRefreshToken();

        Auth.currentAuthenticatedUser()
          .then((res) => {
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                handleLogout();
              } else {
                Auth.currentUserInfo()
                  .then((resp) => {
                    const userObj = {
                      username: res?.username,
                      attributes: resp?.attributes,
                      token: data.getIdToken().getJwtToken(),
                    };
                    dispatch(setActiveUser(true));
                    dispatch(setUserData(userObj));
                    if (profile === "user") {
                      //if (hubspotYou?.Country && hubspotYou?.Country?.toLowerCase() === "india") {
                        dispatch(getIndiaPricingTable());
                      // }else{
                      //   dispatch(getPricingTable(data.getIdToken().getJwtToken()));
                      // }
                    }
                    
                    ApiService.getApiData(
                      `/users/id`,
                      data.getIdToken().getJwtToken()
                    )
                      .then((res) => {
                        if (res?.data && res?.data?.length > 0) {
                          dispatch(setAboutYouStage(res?.data[0]));
                        }
                      })
                      .catch((e) => {
                        console.log(e?.message);
                      });

                    ApiService.getApiData(
                      `/eventDay`,
                      data.getIdToken().getJwtToken()
                    )
                      .then((eventRes) => {
                        let day = "false";
                        let apiBody = {};
                        if (typeof eventRes?.data?.body === "string") {
                          apiBody = JSON.parse(eventRes?.data?.body);
                          day = apiBody?.data;
                        } else {
                          day = eventRes?.data?.data;
                        }
                        dispatch(setEventDay(day));
                      })
                      .catch((e) => {
                        console.log(e?.message);
                      });

                    getHubspotUser(res?.attributes?.email)
                      .then((r) => r.json())
                      .then((resp) => {
                        console.log(resp);
                        const obj = {
                          Org: resp?.properties?.company?.value || "",
                          Dept:
                            resp?.properties?.department_unit_org_chart
                              ?.value || "",
                          PhNo: resp?.properties?.phone?.value || "",
                          Desgn: resp?.properties?.jobtitle?.value || "",
                          Mac: resp?.properties?.macaddress?.value || "",
                          Country:
                            resp?.properties?.custom_country?.value || "",
                        };
                        dispatch(setHubspotStage(obj));
                      })
                      .catch((e) => console.log(e));
                  })
                  .catch((e) => console.log(e));
              }
            });
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dispatch]);

  const handleLogout = () => {
    if (store?.getState()?.users?.activeUser) {
      Auth.signOut().then(() => {
        dispatch(setUserData(null));
        dispatch(setActiveUser(false));
        dispatch(setAboutYouStage(null));
        dispatch(setHubspotStage(null));
        dispatch(setLoggedIn(false));
        window.location.href = "/";
      });
    }
  };

  const handleRender = () => {
    return <Outlet />;
  };

  return <>{handleRender()}</>;
}

export default LandingPrivateComponent;
