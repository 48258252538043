import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { callTrackingApi } from "../../../utils/helperCookie";
import SnackBar from "../../Snackbar";
import ApiService from "../../../service/axiosUtils";
import JSZip from "jszip";
import Axios from "axios";
import downloadSvg from "../../../assets/download-icon2.png";


import videoThub1 from "../../../assets/Add_case_new.jpg";
import videoThub2 from "../../../assets/flagged_items.jpeg";
import videoThub3 from "../../../assets/summary_page.jpeg";
import videoThub4 from "../../../assets/Pattern_of_life.jpeg";
import videoThub5 from "../../../assets/connections.jpeg";
import videoThub6 from "../../../assets/content_analysis.jpeg";
import videoThub7 from "../../../assets/transactions.jpeg";
import videoThub8 from "../../../assets/Global_filters.jpeg";
import videoThub9 from "../../../assets/reports.jpeg";
import playIcon from "../../../assets/play-icon.png";
import installationThumb from "../../../assets/installation-thumb.jpg";

import indiavideoThub1 from "../../../assets/india-thumb-1.jpg";
import indiavideoThub2 from "../../../assets/india-thumb-2.jpg";
import indiavideoThub3 from "../../../assets/india-thumb-3.jpg";
import indiavideoThub4 from "../../../assets/india-thumb-4.jpg";
import indiavideoThub5 from "../../../assets/india-thumb-5.jpg";
import indiavideoThub6 from "../../../assets/india-thumb-6.jpg";


import LoadingScreen from "../../LoadingScreen";

import {
  compareDate,
  formatBytes,
  getLicenseDisable,
} from "../../../utils/utils";
import { setAboutYouStage } from "../../../redux/stages";
import {
  DOWNLOAD_EXE_ERROR,
  LICENSE_ERROR,
  PAGES,
  ROUTES,
  STATUS_DOWNLOADABLE,
  STATUS_DOWNLOADED,
  USER_APPLICANT,
  USER_REJECTED,
  checkSumText,
} from "../../../utils/constants";
import "./DownloadExe.css";
import { dealStageAdminHubspot, getHubspotUser,} from "../../../service/hubspotAxios";
import { setDownloadClicked, setDownloadProgress } from "../../../redux/users";
import VideoModel from "../../../utils/VideoModel/VideoModel";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const DownloadExe = () => {
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const progressBar = useSelector((state) => state?.users?.progress);
  const attributes = useSelector((state) => state?.users?.user?.attributes);
  const emailId = useSelector((state) => state?.users?.user?.attributes?.email);
  const userId = useSelector((state) => state?.users?.user?.username);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const macId = useSelector((state) => state?.stage?.aboutYou?.Mac);
  const eventDay = useSelector((state) => state?.users?.eventDay);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const downloadClicked = useSelector((state) => state?.users?.downloadClicked);
  const planName = aboutYou ? aboutYou.Plan : "Freemium";

  const [pleaseWaitText, setPleaseWaitText] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [errApi, setErrApi] = useState("");
  const [videoStatus, setVideoStatus] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState(0);
  const vedioIds = [
    "911195430",
    "911195760",
    "911195986",
    "911217867",
    "911195645",
    "911195704",
    "911195365",
    "911195900",
    "911195923",
    "912260300"
  ];

  const indiaVedioIds = [
    "1034111302",
    "1034111370",
    "1034111346",
    "1034111323",
    "1034113237",
    "1034113516",
  ];

  

  const closeVideo = () => {
    setVideoStatus(false);
  };
  const openVideo = (index) => {
    setVideoId(vedioIds[index]);
    setVideoStatus(true);
  };


  const indiaopenVideo = (index) => {
    setVideoId(indiaVedioIds[index]);
    setVideoStatus(true);
  };

  var useVarShow =  false;
  
  const concent_get = localStorage.getItem("_con_get");
  if(concent_get != 1){
    useVarShow = true;
  }
  
  // if(aboutYou?.FirstTimeUser === true){
  //   useVarShow = true;
  // }
  // console.log(aboutYou?.FirstTimeUser);
  // console.log("firstime user");
  // console.log(useVarShow);

  const [show, setShow] = useState(useVarShow);

  const handleClose = () => {
    setShow(false);
    localStorage.setItem("_con_get", 1);
  }
  //const handleShow = () => setShow(true);

  // useEffect(() => {
  //   let t = tokenId;
  //   let i = setInterval(() => {
  //     ApiService.getApiData(`/users/id`, t)
  //       .then((res) => {
  //         if (res?.data && res?.data?.length > 0) {
  //           dispatch(setAboutYouStage(res?.data[0]));
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e?.message);
  //       });
  //   }, 10000);

  //   return () => {
  //     clearInterval(i);
  //   };
  // }, []);



  useEffect(() => {
    if (
      attributes?.profile === USER_APPLICANT ||
      attributes?.profile === USER_REJECTED
    ) {
      navigate(ROUTES.LANDING_PAGE);
    }
  }, [attributes]);

  useEffect(() => {
    if (emailId) {
      callTrackingApi({
        email: emailId,
        pagetitle: PAGES.DOWNLOAD,
        pagelink: pathname,
      });
    }
  }, [emailId]);

  useEffect(() => {
    if (!planName) {
      // TODO: Add logic to re-direct to specific route
      navigate(ROUTES.LANDING_PAGE);
    }
  }, []);

  const handleDownload = () => {
    if (
      (progressBar > 0 && progressBar <= 100) ||
      (eventDay === "true" && aboutYou?.FirstTimeUser === false) ||
      (eventDay === "false" && aboutYou?.FirstTimeUser === false && planName=="Freemium") ||
      (eventDay === "false" &&
        aboutYou?.StatusVal !== STATUS_DOWNLOADABLE &&
        aboutYou?.StatusVal !== STATUS_DOWNLOADED)
    ) {
      return true;
    }
    return false;
  };

  const updateDownloadStatus = (payload) => {
    ApiService.patchApiData(`/users/id`, payload, tokenId)
      .then(() => {
        ApiService.getApiData(`/users/id`, tokenId)
          .then((res) => {
            if (res?.data && res?.data?.length > 0) {
              dispatch(setAboutYouStage(res?.data[0]));
            }
          })
          .catch((e) => {
            console.log(e?.message);
          });
      })
      .catch((err) => console.log(err));
  };

  const downloadZipWithTxtFile = async () => {
    if (!planName) {
      return;
    }
    try {
      setLoading(true);
      dispatch(setDownloadClicked(true));

      console.log(hubspotYou.Country);
      console.log("hub data here");

      let day = eventDay;
      if (day === "true") {
        // EVENT DAY IS TRUE
        let payload = {
          EventDay: true,
          status: "AppDownloaded",
          FirstTimeUser: false,
          Plan: "Freemium",
          Country: hubspotYou?.Country
        };

        ApiService.getApiData(
          `/download/app?license=Y&plan=${planName}`,
          tokenId
        )
          .then((resp) => {

            
            console.log(resp);
            console.log("response data in if");

            if (resp?.data) {
              downloadExeFile(
                resp?.data?.appUrl,
                resp?.data?.licenseUrl,
                resp?.data?.checksumTxt,
                payload,
                resp?.data?.fileName
              );
            } else {
              setLoading(false);
              setOpen(true);
              setErrApi(DOWNLOAD_EXE_ERROR);
              resetProgress();
            }
          })
          .catch((e) => {
            setLoading(false);
            setOpen(true);
            setErrApi(e?.message || DOWNLOAD_EXE_ERROR);
            resetProgress();
          });
      } else {
        // EVENT DAY IS FALSE
        let payload = {
          EventDay: false,
          status: "AppDownloaded",
          FirstTimeUser: false,
          Country: hubspotYou?.Country
        };

        ApiService.getApiData(
          `/download/app?license=Y&plan=${planName}&country=${hubspotYou?.Country}`,
          tokenId
        )
          .then((resp) => {

            
            console.log(resp);
            console.log("response data in else wwwwwwwwww");

            if (resp?.data) {
              downloadExeFile(
                resp?.data?.appUrl,
                resp?.data?.licenseUrl,
                resp?.data?.checksumTxt,
                payload,
                resp?.data?.fileName
              );
            } else {
              setLoading(false);
              setOpen(true);
              setErrApi(DOWNLOAD_EXE_ERROR);
              resetProgress();
            }
          })
          .catch((e) => {
            setLoading(false);
            setOpen(true);
            setErrApi(e?.message || DOWNLOAD_EXE_ERROR);
            resetProgress();
          });
      }
    } catch (err) {
      setOpen(true);
      setErrApi(err?.message || DOWNLOAD_EXE_ERROR);
      resetProgress();
    }
  };

  const calculateChecksum = async (responseData) => {
    try {
      const buffer = responseData.data;
      const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const checksum = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

      return checksum;
    } catch (error) {
      return error;
    }
  };

  const downloadExeFile = async (
    zipFileUrl,
    licenseUrl,
    checksumText,
    payload,
    fileName
  ) => {
    let existingZip = null;
    if (zipFileUrl) {

      var LicensePreUrl = await getLicenseUrlPre();
      console.log(LicensePreUrl);

      // if (LicensePreUrl && aboutYou?.Plan === "Freemium") {
      //   // download the text file
      //   const textResponse = await Axios.get(LicensePreUrl, {
      //     responseType: "arraybuffer",
      //   }).catch(() => {
      //     setOpen(true);
      //     setErrApi(DOWNLOAD_EXE_ERROR);
      //     resetProgress();
      //   });
      //   if (!textResponse?.data) {
      //     return;
      //   }

      //   console.log(textResponse?.data);
      //   console.log("middle");
      //   console.log(new Blob([textResponse?.data]));
      // }

      // return;

      // download the zip file
      const existZipResponse = await Axios.get(zipFileUrl, {
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setTotalSize(total);
          const calculatedProgress = (loaded / total) * 100;
          dispatch(setDownloadProgress(calculatedProgress));
          if (calculatedProgress >= 98) {
            dispatch(setDownloadProgress(99));
            setPleaseWaitText(true);
          }
          setLoading(false);
        },
      }).catch((err) => {
        setLoading(false);
        setOpen(true);
        setErrApi(err?.message || DOWNLOAD_EXE_ERROR);
        resetProgress();
      });
      if (!existZipResponse?.data) {
        return;
      }
      const checkSumKeyFromAWS = checksumText;

      const sha256Checksum = await calculateChecksum(existZipResponse);
      console.log(sha256Checksum);

      const areEqual =
        sha256Checksum.toUpperCase() === checkSumKeyFromAWS.toUpperCase();
      if (!areEqual) {
        console.log("not matched");
        setOpen(true);
        setErrApi("Encountering issues; kindly attempt a redownload.");
        return;
      }

      console.log("matched");

      existingZip = await JSZip.loadAsync(existZipResponse.data);
    }
    // TODO: to check for event day flag
    if (LicensePreUrl && aboutYou?.Plan === "Freemium") {
      // download the text file
      const textResponse = await Axios.get(LicensePreUrl, {
        responseType: "arraybuffer",
      }).catch(() => {
        setOpen(true);
        setErrApi(DOWNLOAD_EXE_ERROR);
        resetProgress();
      });
      if (!textResponse?.data) {
        return;
      }
      
      existingZip.file("license.txt", textResponse?.data);
    }

    const content = await existingZip.generateAsync({ type: "blob" });
    dispatch(setDownloadProgress(100));

    updateDownloadStatus(payload);

    if (aboutYou?.Plan === "Freemium") {
      // CALL DEAL API
      const p = getHubspotUser(emailId);
      p.then((r) => r.json()).then(() => {
        const payload = {
          dealname: attributes?.given_name + " " + attributes?.family_name,
          dealstage: "150835226",
        };
        dealStageAdminHubspot(payload, emailId);
      });
    }

    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = fileName || process.env.REACT_APP_ZIP_NAME;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    resetProgress();
    console.log("Reset ZIP");
    
    //save which version downloaded by user
    ApiService.patchApiData(`/users/id?DownloadVersion=${fileName}`, {}, tokenId)
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      
    });
    
  };

  const getLicenseUrlPre = async () => {

    console.log(userId);
    console.log("user id here");

    let doc = "license";
    var preLicenseUrl = "";
    await ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: doc,
        TransactionID: aboutYou?.TransactionID,
      },
      tokenId
    )
      .then((resp) => {
         preLicenseUrl = resp?.data?.appUrl;
      })
      return preLicenseUrl;
  }
  
  const resetProgress = () => {
    setPleaseWaitText(false);
    // dispatch(setDownloadClicked(false));
    dispatch(setDownloadProgress(0));
  };

  const downloadLicenseKey = async () => {
    // Event true and plan Freemium => licenseEvent-Freemium
    // licensecurrent
    let doc = "license";
    // if (eventDay === "true" && aboutYou?.Plan === "Freemium") {
    //   doc = "licenseEvent-Freemium";
    // } else {
    //   doc = "license";
    // }
    ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: doc,
        TransactionID: aboutYou?.TransactionID,
      },
      tokenId
    )
      .then((resp) => {
        const licenseUrl = resp?.data?.appUrl;

        Axios.get(licenseUrl, {
          responseType: "arraybuffer",
        })
          .then((resp) => {
            console.log(resp);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([resp?.data]));
            link.download = "license.txt";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((e) => {
            setOpen(true);
            setErrApi(LICENSE_ERROR || e?.message);
          });
      })
      .catch((e) => console.log(e));
  };
  

  // const concent_check = () => {
    
  //   if(aboutYou?.FirstTimeUser === true){
  //     return true;
  //   }



  // }

  //console.log(planName);
  return (
    <>
      <div className="inner-content-box">
        {/* <div className="notificationstrip position-static">
          This beta version will be accessible till 15<sup>th</sup> March 2024.
        </div> */}

        <div className="download-box">
          <div className="container d-md-flex justify-content-center">
            <div className="download-icon">
              <img src={downloadSvg} alt="" />
            </div>

            <div className="download-description">
              <div className="download-title">
                <h3>
                  CARBN.AI<sup>TM</sup> Installer
                </h3>
                <p>
                  Install the software and dive into the world of actionable
                  insights
                </p>
              </div>

              <div className="download-requirements">
                <p>System Requirements:</p>
                <ul>
                  <li>
                    <span>CPU:</span> 8HT
                  </li>
                  <li>
                    <span>RAM:</span> 16 GB DDR4
                  </li>
                  <li>
                    <span>Disk Storage:</span> 1x 512 GB NVMe SSD
                  </li>

                  <li>
                    <span>Operating System:</span> Windows 10 or 11 64-bit OS
                    (Professional or Enterprise)
                  </li>
                </ul>

                <div className="download-btn-row d-md-flex align-center">
                  <button
                    type="button"
                    className="btn primary-btn btn primary-btn mb-0"
                    onClick={downloadZipWithTxtFile}
                    disabled={handleDownload()}
                  >
                    Download
                  </button>

                  {progressBar > 0 && progressBar <= 100 && (
                    <div className="download-progress-bar exe-progress-bar">
                      <span
                        style={{ width: `${progressBar}%` }}
                        className="stripes"
                      >
                        <div className="progress-count">
                          {progressBar?.toFixed(2)} %
                        </div>
                      </span>
                    </div>
                  )}
                </div>

                <button
                  className="download-license-text download-license-margin"
                  onClick={downloadLicenseKey}
                  style={{
                    pointerEvents:
                      (progressBar > 0 && progressBar <= 100) ||
                      (aboutYou?.StatusVal !== STATUS_DOWNLOADABLE &&
                        aboutYou?.StatusVal !== STATUS_DOWNLOADED) ||
                      compareDate(aboutYou?.LicenseExpDate)
                        ? "none"
                        : "",
                  }}
                  disabled={
                    !userId ||
                    (progressBar > 0 && progressBar <= 100) ||
                    (eventDay === "false" &&
                      aboutYou?.StatusVal !== STATUS_DOWNLOADABLE &&
                      aboutYou?.StatusVal !== STATUS_DOWNLOADED) ||
                    compareDate(aboutYou?.LicenseExpDate)
                  }
                >
                  Download license key
                </button>

                <a href="https://www.clear-trail.com/CARBN.AI/CARBN.AI-data-sheet.pdf" target="_blank" className="data-sheet">Data sheet</a>

                {iOS && (
                  <div style={{ paddingTop: "15px" }}>
                    Download available only on Windows OS
                  </div>
                )}

                <div style={{ marginTop: "15px" }}>
                  For any queries, please call{" "}
                  <a href="tel:+918062281345">+91 80622 81345</a> or email{" "}
                  <a href="mailto:support@carbn.ai">support@carbn.ai</a>.
                </div>
              </div>
            </div>
            {/* <div className="download-info">
              
              <div className="download-info-left">
                CARBN Installer <span>Application</span>
              </div>
              <div className="download-info-right">
                {totalSize > 0 && formatBytes(totalSize, 2)}
              </div>
            </div> */}

            {/* <div>
              {pleaseWaitText && (
                <p>We are preparing your Zip file. Please wait...</p>
              )}
            </div> */}
            {/* {macId && (
              <p>
                Please run the application on the same system registered before
                ({macId})
              </p>
            )} */}
          </div>
        </div>

        {aboutYou?.StatusVal &&
        <div className="download-main-box">
          <div className="download-tutorial-box">
            <div className="container">
              <div className="journey-title">
                <h3>
                  How to install CARBN.AI<sup>TM</sup>
                </h3>
              </div>

              <div className="tutorial-col install-video-call">
                <img src={installationThumb} alt="Video" />
                <button
                  type="button"
                  className="play-btn"
                  onClick={() => openVideo(9)}
                >
                  <img src={playIcon} alt="Play Icon" />
                </button>
              </div>
            </div>
          </div>

          {/* <div className="install-info-box">
              <h4>
                License key sent to your inbox at
                <strong> {emailId}</strong>
              </h4>
              <p>Please enter during setup when nudged.</p>

              <div className="license-btn-row d-md-flex justify-content-center">
                <button
                  className="btn primary-border-btn"
                  onClick={downloadLicenseKey}
                  disabled={getLicenseDisable(eventDay, aboutYou)}
                >
                  Download license key
                </button>
                <NavLink
                  className={"btn secondary-border-btn"}
                  to={ROUTES.DASHBOARD}
                >
                  Go to your Dashboard
                </NavLink>
              </div>
            </div> */}
        </div>
        }
        
        {aboutYou?.StatusVal &&
        <div className="cover-box">
          
          <>
           

                <div className="journey-box">
                  <div className="container">
                    <div className="journey-title">
                      <h3>Video tutorials to help you get started</h3>
                    </div>

                    <div className="tutorial-box-download">
                      <div className="tutorial-col">
                        <img src={indiavideoThub1} alt="Video" />
                        <button
                          type="button"
                          className="play-btn"
                          onClick={() => indiaopenVideo(0)}
                        >
                          <img src={playIcon} alt="Play Icon" />
                        </button>
                      </div>

                      <div className="tutorial-col">
                        <img src={indiavideoThub2} alt="Video" />
                        <button
                          type="button"
                          className="play-btn"
                          onClick={() => indiaopenVideo(1)}
                        >
                          <img src={playIcon} alt="Play Icon" />
                        </button>
                      </div>

                      <div className="tutorial-col">
                        <img src={indiavideoThub3} alt="Video" />
                        <button
                          type="button"
                          className="play-btn"
                          onClick={() => indiaopenVideo(2)}
                        >
                          <img src={playIcon} alt="Play Icon" />
                        </button>
                      </div>

                      <div className="tutorial-col">
                        <img src={indiavideoThub4} alt="Video" />
                        <button
                          type="button"
                          className="play-btn"
                          onClick={() => indiaopenVideo(3)}
                        >
                          <img src={playIcon} alt="Play Icon" />
                        </button>
                      </div>

                      <div className="tutorial-col">
                        <img src={indiavideoThub5} alt="Video" />
                        <button
                          type="button"
                          className="play-btn"
                          onClick={() => indiaopenVideo(4)}
                        >
                          <img src={playIcon} alt="Play Icon" />
                        </button>
                      </div>

                      <div className="tutorial-col">
                        <img src={indiavideoThub6} alt="Video" />
                        <button
                          type="button"
                          className="play-btn"
                          onClick={() => indiaopenVideo(5)}
                        >
                          <img src={playIcon} alt="Play Icon" />
                        </button>
                      </div>

                      

                      
                    </div>
                  </div>
                </div>

         
              
           </>


          {/* start */}
          <div className="journey-box text-center">
            <div className="container">
              <div className="journey-title">
                <h3>Frequently Asked Questions</h3>
              </div>

              {/* accordion box start */}
              <div
                className="accordion wow fadeInUp"
                data-wow-delay="0.2s"
                id="FAQ_accordion"
              >
                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-1"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-1"
                    >
                      I have downloaded the Installer, what next?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-1"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      Double click or run the downloaded .exe file as an
                      Administrator and follow the on-screen instructions to
                      install CARBN.AI<sup>TM</sup>.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-2"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-2"
                    >
                      How do I install CARBN.AI<sup>TM</sup>?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-2"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-2"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        To begin the installation of CARBN.AI<sup>TM</sup>{" "}
                        software, follow these steps:
                      </p>
                      <ul>
                        <li>
                          Double click or run the downloaded .exe file as an
                          Administrator. The{" "}
                          <b>
                            Welcome to CARBN.AI<sup>TM</sup> Setup
                          </b>{" "}
                          dialog appears. This window will check the system
                          compatibilities including Operating System, CPU
                          threads and RAM, before proceeding with the
                          installation.
                        </li>
                        <li>
                          Click <b>Next</b>. The <b>License Agreement</b> dialog
                          appears. Read and click the <b>I Agree</b> button to
                          accept the End User License Agreement. The{" "}
                          <b>Installation and Data Path Locations</b> dialog
                          appears.
                        </li>

                        <li>
                          The default installation directory for CARBN.AI
                          <sup>TM</sup> is C:\Program Files\ which is non-
                          editable. Choose the data path directory which is by
                          default set to C:\. This path is the drive where the
                          user wants the data to be stored.
                        </li>
                        <li>
                          Click <b>Install</b>. The next window will verify disk
                          space for both installation and data drives.
                        </li>
                        <li>
                          If the prerequisites for the installation are met, the
                          installation would proceed to install the CARBN.AI
                          <sup>TM</sup> software.
                        </li>
                        <li>
                          A window will appear after the installation is
                          complete. Select the option for rebooting your system.
                          Click <b>Finish</b>. The CARBN.AI<sup>TM</sup>{" "}
                          software is successfully installed in your system now.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-3"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-3"
                    >
                      What is CARBN.AI<sup>TM</sup>?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-3"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      CARBN.AI<sup>TM</sup> is an offline IP data decoding and
                      analysis solution that helps analysts uncover
                      correlations, build a suspect's profile, and connect all
                      the data points across cleartext, encrypted and unknown
                      data transactions.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-4"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-4"
                    >
                      How does CARBN.AI<sup>TM</sup> work?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-4"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-4"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      CARBN.AI<sup>TM</sup> works by processing and analyzing
                      imported PCAP data. Once the data has been decoded,
                      CARBN.AI<sup>TM</sup>'s analysis engine works to uncover
                      patterns, correlations, and links between distinct data
                      points and displays the results in a user-friendly
                      interface so that analysts can get useful insights about
                      suspects.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-5"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-5"
                    >
                      Are there specific system requirements for CARBN.AI
                      <sup>TM</sup> installation?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-5"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-5"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, CARBN.AI<sup>TM</sup> requires the following system
                        specifications:
                      </p>
                      <ul>
                        <li>
                          <strong>CPU:</strong> 8HT
                        </li>
                        <li>
                          <strong>RAM:</strong> 16 GB DDR4
                        </li>
                        <li>
                          <strong>Disk Storage:</strong> 1x 512 GB NVMe SSD
                        </li>

                        <li>
                          <strong>Operating System:</strong> Windows 10 or 11
                          64-bit OS (Professional or Enterprise)
                        </li>
                      </ul>
                      <p>
                        Meeting these specifications will ensure that your
                        system performs optimally and is compatible with
                        CARBN.AI<sup>TM</sup>.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-6">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-6"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-6"
                    >
                      Is technical support available during the installation
                      process?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-6"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-6"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      No, technical support is not available during the
                      installation process. However, we provide a Readme file
                      along with the installer to assist you in a smooth
                      installation experience. If you encounter any issues or
                      have questions, please refer to our support resources on
                      the website.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-7">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-7"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-7"
                    >
                      Can I choose a custom installation directory, If not why?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-7"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-7"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        No, custom installation directories are not supported.
                      </p>
                      <p>
                        The installation is done at the default path -
                        C:\Program Files. This default location is necessary as
                        certain essential services need to be installed in
                        Program Files for the proper functioning of CARBN.AI
                        <sup>TM</sup>. Deviating from the default path may lead
                        to system instability. Therefore, the installation
                        directory is predefined and non-editable to ensure the
                        stability and integrity of the software on your system.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-8">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-8"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-8"
                    >
                      What is a letter of verification?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-8"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-8"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      A letter of verification is a written communication that
                      is requested before providing CARBN.AI<sup>TM</sup>{" "}
                      downloadable to confirm the authenticity and legitimacy of
                      a customer/agency/organization. This letter is provided by
                      ClearTrail that owns the CARBN.A<sup>TM</sup> software, to
                      be signed and shared back by the
                      customer/agency/organization.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-9">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-9"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-9"
                    >
                      Is there a demo or trial version available?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-9"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-9"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      Yes, a freemium model is available to try for 1 month with
                      a data limit of up to 2 GB.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-10">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-10"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-10"
                    >
                      Is this software compatible with other operating systems
                      besides Windows?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-10"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-10"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      At present, it is compatible only with Windows 10, 11 Pro
                      and enterprise versions with 64-bit system type.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-11">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-11"
                      aria-expanded="false"
                      aria-controls="FAQ-collapse-11"
                    >
                      What should I do if I experience technical issues?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-11"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-11"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        If you encounter technical issues, please contact our
                        support team for assistance and follow these steps:
                      </p>
                      <ul>
                        <li>
                          Navigate to the CARBN.AI<sup>TM</sup> user profile
                          section
                        </li>
                        <li>
                          Click the <b>Product Logs</b> option to download the
                          log details.
                        </li>
                        <li>
                          Provide these log details to the technical support
                          team. This information will assist our support team in
                          quick diagnosing and addressing the specific issues
                          you are facing.
                        </li>
                      </ul>
                      <p>
                        <b>NOTE</b>: These log details do not contain any
                        sensitive information of your data.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-12">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-12"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-12"
                    >
                      Why is the website not responding?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-12"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-12"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>Reasons for website unresponsiveness could include:</p>
                      <ul>
                        <li>
                          Network issues: Check your internet connection to
                          ensure it is stable.
                        </li>
                        <li>
                          Server overload: High server demand may cause
                          temporary unresponsiveness.
                        </li>
                        <li>
                          Maintenance: Scheduled maintenance might temporarily
                          impact website availability.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-13">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-13"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-13"
                    >
                      What do I do if I encounter an error message?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-13"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-13"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      Look for the solution in the Help section or try asking
                      the chatbot.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-14">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-14"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-14"
                    >
                      What types of licences are available?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-14"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-14"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        <b>Freemium</b>: This license allows users to access a
                        basic version of CARBN.AI<sup>TM</sup> without charge.
                        The complimentary version serves as an entry point for
                        users to comprehend the core value of the product
                        without a financial commitment. Qualification for this
                        option requires at least a DSP level letter.
                      </p>
                      <p>
                        <b>Bundled Credit Pack</b>: Under this license, users
                        can make upfront purchases of credit packs. These
                        credits directly correspond to the volume of data
                        processed by the system, deducting a specific number of
                        credits from the pack for each transaction. Once the
                        credits are depleted, users have the option to acquire
                        additional packs as needed.
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* Item */}

                {/* Item */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-15">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-15"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-15"
                    >
                      How is the pricing structured?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-15"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-15"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        CARBN.AI<sup>TM</sup> license pricing is structured as
                        shown
                      </p>
                      <div className="accordion-table">
                        <table>
                          <tr>
                            <td>Pricing</td>
                            <td>Free</td>
                            <td>$250</td>
                            <td>$500</td>
                            <td>$750</td>
                          </tr>

                          <tr>
                            <td>Data Limit</td>
                            <td>Up to 1GB</td>
                            <td>150 GB one-time credit</td>
                            <td>300 GB one-time credit</td>
                            <td>600 GB one-time credit</td>
                          </tr>

                          <tr>
                            <td>Time Limit</td>
                            <td>1 month</td>
                            <td>Valid up to 3 months</td>
                            <td>Valid up to 6 months</td>
                            <td>Valid up to 12 months</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* Item */}

                {/* Item */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-16">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-16"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-16"
                    >
                      How does CARBN.AI<sup>TM</sup>'s pricing work?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-16"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-16"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Users can choose the pricing plan that aligns with their
                        needs, considering factors such as data requirements and
                        the desired duration of access. The one-time credit
                        provided with each paid version allows users to consume
                        the allotted data over the specified timeframe.
                      </p>
                      <p>
                        Pricing details are structured into different plans with
                        varying data limits and durations:
                      </p>

                      <ul>
                        <li>
                          <b>
                            Free Version with Data Limit up to 1GB for 1 month:
                          </b>
                          <ul>
                            <li>
                              Description: This is a basic version of the
                              software provided at no cost.
                            </li>
                            <li>
                              Data Limit: Users can utilize the software with a
                              data limit of up to 1GB.
                            </li>
                            <li>
                              Duration: The free version is valid for 1 month.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            $250 Version with 150 GB one-time credit valid up to
                            3 months:
                          </b>
                          <ul>
                            <li>
                              Description: This is a paid version of the
                              software with enhanced features.
                            </li>
                            <li>
                              Data Limit: Users receive a one-time credit of 150
                              GB to use within the allotted period.
                            </li>
                            <li>
                              Duration: The $250 version is valid for 3 months
                              from the date of purchase.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            $500 Version with 300 GB one-time credit valid up to
                            6 months:
                          </b>
                          <ul>
                            <li>
                              Description: A higher tier paid version with
                              increased data allowance and extended validity.
                            </li>
                            <li>
                              Data Limit: Users receive a one-time credit of 300
                              GB for usage within the specified timeframe.
                            </li>
                            <li>
                              Duration: The $500 version is valid for 6 months
                              from the date of purchase.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            $750 Version with 600 GB one-time credit valid up to
                            12 months:
                          </b>
                          <ul>
                            <li>
                              Description: The premium version of the software
                              with the highest data limit and longest validity.
                            </li>
                            <li>
                              Data Limit: Users are granted a one-time credit of
                              600 GB for usage throughout the designated period.
                            </li>
                            <li>
                              Duration: The $750 version is valid for 12 months
                              from the date of purchase.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-17">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-17"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-17"
                    >
                      Is CARBN.AI<sup>TM</sup> free to use?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-17"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-17"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, there is a free version of the CARBN.AI
                        <sup>TM</sup> available. However, it's important to note
                        the specific terms and limitations associated with the
                        free version. Here are the key details:
                      </p>
                      <p>
                        <b>Free Version:</b>
                      </p>
                      <ul>
                        <li>Data Limit: Up to 2 GB.</li>
                        <li>Duration: Valid for 1 month.</li>
                      </ul>
                      <p>
                        While the free version is accessible without a monetary
                        cost, users are bound by the 2 GB data limit and the
                        1-month duration. If users require more data or an
                        extended duration, they may consider upgrading to one of
                        the paid versions with higher data limits and longer
                        validity periods.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-18">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-18"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-18"
                    >
                      How does the freemium plan work?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-18"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-18"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      Once you sign up, you have 1 month and 2 GB data limit to
                      use CARBN.AI<sup>TM</sup> free of charge. You don’t need
                      to provide your credit/debit card details until the trial
                      period is over.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-19">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-19"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-19"
                    >
                      What payment methods are accepted?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-19"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-19"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>Accepted payment methods for license purchase are:</p>
                      <p>
                        <b>Offline</b>: We offer the option to receive an
                        invoice based on provided bank details, allowing users
                        to make payments later through banking channels or
                        online transactions.
                      </p>
                      <p>
                        <b>Online</b>: Payment can be made via online debit or
                        credit cards.
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-20">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-20"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-20"
                    >
                      Are there any hidden fees?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-20"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-20"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      No, there are no hidden fees.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-21">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-21"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-21"
                    >
                      What happens when I hit my usage limits?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-21"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-21"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      When you reach your usage limits, a warning message will
                      be displayed to alert you about the constraints. Prior to
                      reaching these limits, users have the option to purchase
                      upgrade plans. Acquiring a new plan provides an invoice, a
                      downloadable.exe file, and a new license key.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-22">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-22"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-22"
                    >
                      Is CARBN.AI<sup>TM</sup> an online or offline software?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-22"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-22"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      CARBN.AI<sup>TM</sup> is primarily designed as offline
                      software; nevertheless, it is also capable of functioning
                      within an online environment.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-23">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-23"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-23"
                    >
                      Can I upgrade or downgrade my license?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-23"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-23"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      No, there is no facility to downgrade the CARBN.AI
                      <sup>TM</sup> license.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-24">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-24"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-24"
                    >
                      Can I install CARBN.AI<sup>TM</sup> on multiple devices
                      with the same license key?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-24"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-24"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      No, a single license key cannot be used to install the
                      CARBN.AI<sup>TM</sup> software. Since the license is MAC
                      bind, it can only be used on one device.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-25">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-25"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-25"
                    >
                      What should I do if CARBN.AI<sup>TM</sup> installation is
                      successful and CARBN.AI<sup>TM</sup> services are not up?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-25"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-25"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Check that the following services are properly
                        functioning in the background.
                      </p>
                      <ul>
                        <li>Maven1</li>
                        <li>RabbitMQ</li>
                        <li>
                          ClearTrail CARBN.AI<sup>TM</sup> Core Service
                        </li>
                        <li>
                          ClearTrail CARBN.AI<sup>TM</sup> Elasticsearch
                        </li>
                        <li>
                          ClearTrail CARBN.AI<sup>TM</sup> UI Service
                        </li>
                        <li>ClearTrail Data Enrichment API</li>
                        <li>
                          ClearTrail Data Enrichment Authentication Server
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-26">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-26"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-26"
                    >
                      Can I install the CARBN.AI<sup>TM</sup> without
                      administrative privileges?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-26"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-26"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        No, User cannot install CARBN.AI<sup>TM</sup> without
                        administrative privileges.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-27">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-27"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-27"
                    >
                      Is the system asking for a reboot after installing
                      CARBN.AI<sup>TM</sup>?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-27"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-27"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      Yes, the system asks for reboot, and it is recommended
                      after the installation is complete.
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-28">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-28"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-28"
                    >
                      Windows defender screens are appearing as a user, can I
                      accept or cancel the screens?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-28"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-28"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, accept to run anyway. This is not a
                        malware/bug/virus.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-29">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-29"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-29"
                    >
                      Upon uninstallation, Is reboot necessary?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-29"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-29"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, rebooting the system after uninstalling software is
                        necessary to remove residual file/data and processes
                        associated with the software may still be in use or
                        loaded into memory. Rebooting ensures that all
                        components are fully removed from the system.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-30">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-30"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-30"
                    >
                      Why is the installation done on the default path?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-30"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-30"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        Installation is done at default path because there are
                        certain services required to be installed at specific
                        path and hence if user provide any other path, it will
                        make the system unstable.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}

                {/* Item */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-31">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-31"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-31"
                    >
                      Why there is a need to provide Datapath?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-31"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-31"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                        As CARBN.AI<sup>TM</sup> is an offline software, we need
                        a data path to store the data generated and save all the
                        resources generated on processing.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Item */}
              </div>
              {/* accordion box end 

              <div className="text-center">
                <a href="#" className="btn primary-border-btn inline-block">
                  View all
                </a>
              </div>*/}
            </div>
          </div>
          {/* end */}
        </div>
          }


        <Modal show={show} onHide={handleClose} keyboard={false} backdrop={false} className="guidelineModal">
          
          <Modal.Body>
            
              <h2>CARBN.AI<sup>TM</sup> security guidelines</h2>
              <ul>
                <li><strong>Your Data Stays Private:</strong> ClearTrail respects your privacy. We do not access or store any data from your use of CARBN.AI<sup>TM</sup>. Your information remains entirely yours.</li>
                <li><strong>Designed for Air-Gapped Machines:</strong> For maximum security, run CARBN.AI<sup>TM</sup> on a computer that's not connected to the internet. This protects against online threats and ensures that your data stays private.</li>
                <li><strong>Antivirus Whitelisting:</strong> Some antivirus programs might flag new software. To prevent any installation issues with CARBN.AI<sup>TM</sup>, please add it as an exception in your antivirus settings. We assure you, that CARBN.AI<sup>TM</sup> is safe and secure.</li>

              </ul>

              
              <Button onClick={handleClose} className="btn primary-btn">Got It</Button>
              

          </Modal.Body>
          
        </Modal>

      

        {/* end */}
        <SnackBar
          open={open}
          setOpen={setOpen}
          severity="error"
          message={errApi}
        />
        <VideoModel
          videoStatus={videoStatus}
          videoId={videoId}
          closeVideo={closeVideo}
        ></VideoModel>

      
      

      </div>
      {loading && <LoadingScreen open={loading} />}
    </>
  );
  // }
};

export default DownloadExe;
